<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tool đối soát</h6>
        </template>
        <div>
          <b-row>
            <b-col>
              <b-form-group label="Ngày bắt đầu(*)">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="formInput.from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Ngày kết thúc(*)">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="formInput.to"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Merchant">
                <b-form-select
                    v-model="formInput.merchant"
                    :options="options.merchant"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mã giao dịch">
                <b-form-input
                    v-model="formInput.payment_id"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mã thanh toán">
                <b-form-input
                    v-model="formInput.wallet_transaction_id"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col class="text-right">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="searchDataWallet(1)"
              >Tìm kiếm</b-button
            >
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="$bvModal.show('export-desc')"
              >Xuất excel</b-button
            >&nbsp;
          </b-col>
        </b-row>
        <div>
          <b-row>
            <b-col> </b-col>
            <b-col> </b-col>
            <b-col >
              <b @click="getTotalTransaction()" style="cursor: pointer">Tổng tiền :
                <span v-if="this.totalAmount == null" > *** <i  class="menu-icon flaticon-eye" > </i> </span>
                <span v-if="this.totalAmount != null">{{ numberFormat(this.totalAmount) }} VNĐ</span>
              </b>
            </b-col>

          </b-row>
          <b-row>
            <b-col> </b-col>
            <b-col> </b-col>
            <b-col >
              <b>Tổng số GD: </b> <span> {{ numberFormat(this.total) }} giao dịch </span>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="Danh sách" active>
              <b-table
                :items="items"
                :fields="fields"
                ref="table"
                striped
                hover
                responsive
                caption-top
              >
<!--                amount_cashback-->

                <template v-slot:head(amount_cashback)="data">
                  {{ data.field.label }}
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="right" title="Cashback từ Ví, không tính voucher cashback"></i>
                </template>

                <template #cell(index)="data">
                  {{ (view.currentPage - 1) * 25 + (data.index + 1) }}
                </template>
                <template #cell(order_amount)="data">
                  {{ numberFormat(data.item.order_amount) }}
                </template>
                <template #cell(amount_final)="data">
                  {{ numberFormat(data.item.amount_final) }}
                </template>
                <template #cell(total)="data">
                  {{ numberFormat(data.item.total) }}
                </template>
                <template #cell(amount_cashback)="data">
                  {{ numberFormat(data.item.amount_cashback) }}
                </template>

                <template #cell(created_at)="data">
                  <span>
                    {{ formatDate(data.item.created_at) }}
                  </span>
                </template>
                <template #cell(updated_at)="data">
                  <span>
                    {{ formatDate(data.item.updated_at) }}
                  </span>
                </template>
              </b-table>

              <div class="mt-3">
                <b-pagination-nav
                  v-model="view.currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="view.paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </b-card-group>
    <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
      <br />
      <div class="d-block text-center">
        <b-form-textarea
          placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
          v-model="formInput.exportDesc"
          rows="3"
        ></b-form-textarea>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
        <b-button size="sm" variant="success" @click="exportData()">
          Xuất
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      items: [],
      total: 0,
      totalAmount: null,
      fields: [],
      formInput: {
        wallet_type: 'CASHBACK',
        from: this.getYesterday(),
        to: this.getCurrentDay(),
        payment_id: null,
        wallet_transaction_id: null,
        merchant: null,
        exportDesc: null,
      },
      options: {
        merchant: [
          { value: null, text: "Tất cả" },
          { value: "funtapcashback", text: "funtapcashback" },
          { value: "redriver", text: "redriver" },
        ],
      },
      view: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tool đối soát Cashback", route: "reconcileCashback" },
    ]);
    this.searchDataWallet();
  },
  methods: {
    getTotalTransaction(){
      if (this.formInput.from > this.formInput.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      let params = this.formInput;
      params = this.convert(params)


      this.$bus.$emit("show-loading", true);
      CmsRepository.totalReconcileWallet(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
            } else {
              this.totalAmount = response.data.data.data.total;
              this.notifyAlert("success", "Lấy dữ liệu thành công");
            }
            this.$bus.$emit("show-loading", false);
            if (typeof this.formInput.merchant == 'undefined') {
              this.formInput.merchant = null
            }
          })
          .catch(() => {
            this.notifyAlert("warn", "Có lỗi xảy ra");
          });
    },
    searchDataWallet(numberPage = null) {
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      } else {
        this.$router.replace({});
        this.view.currentPage = 1;
      }

      if (this.formInput.from > this.formInput.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }

      let params = this.formInput;
      params = this.convert(params);

      params.page = this.view.currentPage ?? 1;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listShowReconcileWallet(params)
        .then((response) => {
          this.items = [];
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.fields = response.data.data.fields;
            this.totalAmount = response.data.data.totalAmount;
            this.total = response.data.data.total;
            this.view.paginate.total = response.data.data.total;
            this.view.paginate.totalPage =
              response.data.data.lastPage ?? response.data.data.last_page ?? 1;
            this.view.paginate.currentPage =
              response.data.data.currentPage ??
              response.data.data.current_page ??1;
            this.notifyAlert("success", "Lấy dữ liệu thành công");
          }
          this.$bus.$emit("show-loading", false);
          if (typeof this.formInput.merchant == 'undefined') {
            this.formInput.merchant = null
          }
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xảy ra");
        });
    },
    exportData() {
      if (this.formInput.from > this.formInput.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      let params = this.formInput

      if (
        this.formInput.exportDesc != null &&
        this.formInput.exportDesc != ""
      ) {
        params.export_desc = this.formInput.exportDesc;
      }
      // params.page = this.view.currentPage ?? 1;
      this.$bvModal.hide("export-desc");
      this.$bus.$emit("show-loading", true);
      params = this.convert(params)

      CmsRepository.exportFileReconcileWallet(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert(
              "success",
              "Tạo tiến trình thành công. Vui lòng chờ"
            );
          }
          this.$bus.$emit("show-loading", false);
          if (typeof this.formInput.merchant == 'undefined') {
            this.formInput.merchant = null
          }
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xẩy ra");
        });
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
    updateWalletType(e) {
      this.formInput.wallet_type = e;
    },
  },
  created() {

  },
  watch: {
    "view.currentPage"() {
      this.searchDataWallet();
    },
  },


};
</script>
